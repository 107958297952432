<template>
  <div>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      :table-title="'Статистика по заведениям'"
    >
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'type'">
          {{
            props.formattedRow[props.column.field]['description'] + ' (' + props.formattedRow[props.column.field]['id'] + ')'
          }}
        </template>
        <template v-else>
          {{ props.formattedRow[props.column.field] }}
        </template>
      </template>
      <template v-slot:base-table-actions-bottom>
        <div class="wrapper">
          <div class="sum-data">
            <h3 style="font-weight: bold">Суммарно</h3>
            <span class="inline-text-sum">Заказов: {{ sumElem.count }}</span>
            <span class="inline-text-sum">Успешно: {{ sumElem.count_success }}</span>
            <span class="inline-text-sum">Сумма: {{ sumElem.sum }}</span>
            <span class="inline-text-sum">Сумма комиссий: {{ sumElem.sum_commissions }}</span>
          </div>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "categoriesList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props: [
    'user'
  ],
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Статистика по заведениям",
        filtersUrl: "/statistic/restaurantCommission/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
            ],
            column: 2,
            defaultValue: 3,
            setDefaultInFilters: 3
          },
        }
      },
      columns: [
        {
          label: 'Заведение',
          field: 'restaurant',
          width: '17%',
          sortable: true,
        },
        {
          label: 'Всего заказов',
          field: 'count',
          type: 'number',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Успешные',
          field: 'count_success',
          type: 'number',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Сумма',
          field: 'sum',
          type: 'number',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Сумма комиссий',
          field: 'commission',
          type: 'number',
          width: '15%',
          sortable: true,
        },
      ],
      sumElem: {
        count: 0,
        count_success: 0,
        sum: 0,
        sum_commissions: 0,
      },
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      this.sumElem = {
        count: 0,
        count_success: 0,
        sum: 0,
        sum_commissions: 0,
      }
      for (let elem of list) {
        this.sumElem.count += Number(elem['count']);
        this.sumElem.count_success += Number(elem['count_success']);
        this.sumElem.sum += Number(elem['sum']);
        this.sumElem.sum_commissions += Number(elem['commission']);
        data.push({
          restaurant: elem['restaurant']['title'] + ' (' + elem['restaurant']['id'] + ')',
          sum: elem['sum'],
          commission: elem['commission'],
          count: elem['count'],
          count_success: elem['count_success'],
        });
      }
      return data;
    },
  },
  computed: {
  }
}
</script>
