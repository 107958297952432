<template>
  <div>
    <base-table
      :columns="columns"
      :per-page="250"
      :table-title="'Заказ ' + this.order.id"
      :wrapperFiltersData="wrapperFiltersData"
      :render-list-to-show="listToShow"
      :enable-pagination="false"
    >
      <template v-slot:base-table-actions="props">
        <div class="fx col-12">
          <span>ID заказа: {{ order.id }}</span>
        </div>
        <div class="fx align-center col-12">
          <div class="col-1">
            <span>Статус: </span>
          </div>
          <div class="col-3">
            <instance-input-select :index="order.id" :object="{
            specifyKeyForLabel: 'description',

            selectedKey: 'status',
            getDataLink: '/order/getOrderStatuses',
            defaultValue: order.status_id,
            closeOnSelect: true,
            noGroupSelect: true,
            allowEmpty: false,
          }"
                                   @add-filter-data-single="statusSelected"
            ></instance-input-select>
          </div>
        </div>
        <div class="fx col-12">
          <span>Тип доставки: {{ order.delivery_type }}</span>
        </div>
        <div class="fx col-12">
          <span>Тип оплаты: {{ order.payment_type }}</span>
        </div>
        <div class="fx col-12">
          <span>Дата создания: {{ order.created_at }}</span>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'additional_ingredients'">
          <span v-if="props.formattedRow[props.column.field].length" v-for="(ap, index) in props.formattedRow[props.column.field]">
            {{ ap['product_additional_ingredient']['title'] + ' ' + ap['product_additional_ingredient']['additional_ingredient_group']['title'] + ' (' + ap['price'] + 'р)' }} <br>
          </span>
          <span v-else></span>
        </template>
        <template v-else>
          {{ props.formattedRow[props.column.field] }}
        </template>
      </template>
    </base-table>
  </div>
</template>
<script>

import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import BaseTable from "../../../components/table/baseTable";
import InstanceInputSelect from "../../../components/table/instanceInputSelect.vue";
export default {
  name: "orderInfo",
  components: {
    InstanceInputSelect,
    BaseTable
  },
  props: [
    'order'
  ],
  mixins: [
    BaseAdminMixin
  ],
  data: function () {
    return {
      wrapperFiltersData: {
        filtersUrl: "/order/getProducts/" + this.order.id,
        startWithRequest: true,
        showCollapseFilter: false,
        showResetFilterButton: false,
        filtersItems: false,
      },
      orderObject: null,
      columns: [
        {
          label: 'Название',
          field: 'title',
          width: '20%',
        },
        {
          label: 'Доп. ингредиенты',
          field: 'additional_ingredients',
          width: '20%',
        },
        {
          label: 'Цена',
          field: 'price',
          type: 'number',
          width: '12%',
        },
        {
          label: 'Кол-во',
          field: 'count',
          type: 'number',
          width: '10%',
        },
        {
          label: 'Сумма',
          field: 'sum',
          type: 'number',
          width: '15%',
        },
      ],
    };
  },

  async mounted() {
    this.orderObject = this.order
  },

  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          title: elem['product']['title'],
          additional_ingredients: elem['additional_ingredients'],
          price: elem['price'],
          count: elem['count'],
          sum: elem['sum']
        });
      }
      return data;
    },
    statusSelected: async function (key, statusId) {
      let self = this;
      await this.doWithLoader(() => self.doWithStopRequest(
        async () => {
          let resultStatusChanged = await self.sendRequest('/order/changeStatus', 'post', {
            order_id: self.orderObject.id,
            status: statusId
          });
          if (resultStatusChanged) {
            await self.alertMessage('Статус заказа изменен', 'good');
          }
        }
      ));
    }
  },
}
</script>
