var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-table',{attrs:{"columns":_vm.columns,"per-page":250,"table-title":'Заказ ' + this.order.id,"wrapperFiltersData":_vm.wrapperFiltersData,"render-list-to-show":_vm.listToShow,"enable-pagination":false},scopedSlots:_vm._u([{key:"base-table-actions",fn:function(props){return [_c('div',{staticClass:"fx col-12"},[_c('span',[_vm._v("ID заказа: "+_vm._s(_vm.order.id))])]),_vm._v(" "),_c('div',{staticClass:"fx align-center col-12"},[_c('div',{staticClass:"col-1"},[_c('span',[_vm._v("Статус: ")])]),_vm._v(" "),_c('div',{staticClass:"col-3"},[_c('instance-input-select',{attrs:{"index":_vm.order.id,"object":{
          specifyKeyForLabel: 'description',

          selectedKey: 'status',
          getDataLink: '/order/getOrderStatuses',
          defaultValue: _vm.order.status_id,
          closeOnSelect: true,
          noGroupSelect: true,
          allowEmpty: false,
        }},on:{"add-filter-data-single":_vm.statusSelected}})],1)]),_vm._v(" "),_c('div',{staticClass:"fx col-12"},[_c('span',[_vm._v("Тип доставки: "+_vm._s(_vm.order.delivery_type))])]),_vm._v(" "),_c('div',{staticClass:"fx col-12"},[_c('span',[_vm._v("Тип оплаты: "+_vm._s(_vm.order.payment_type))])]),_vm._v(" "),_c('div',{staticClass:"fx col-12"},[_c('span',[_vm._v("Дата создания: "+_vm._s(_vm.order.created_at))])])]}},{key:"base-table-row",fn:function(props){return [(props.column.field === 'additional_ingredients')?_vm._l((props.formattedRow[props.column.field]),function(ap,index){return (props.formattedRow[props.column.field].length)?_c('span',[_vm._v("\n          "+_vm._s(ap['product_additional_ingredient']['title'] + ' ' + ap['product_additional_ingredient']['additional_ingredient_group']['title'] + ' (' + ap['price'] + 'р)')+" "),_c('br')]):_c('span')}):[_vm._v("\n        "+_vm._s(props.formattedRow[props.column.field])+"\n      ")]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }